
.swiper-button {
  --swiper-navigation-size: calc(var(--base-margin) * 30);
  height: calc(var(--base-margin) * 50);
  width: calc(var(--base-margin) * 50);

  &--minimal {
    width: fit-content;
    background: transparent !important;
    --swiper-navigation-sides-offset: 0;
  }
}
